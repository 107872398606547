// import firebase from 'firebase/compat/app';

export default {
    state: {
        loading: false,
        error: null
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {
        loading (context, payload) {
            context.commit('setLoading',payload)
        },
        setError (context, payload) {
            context.commit('setError',payload)
        },
        clearError (context) {
            context.commit('clearError')
        },
    },
    getters: {
        loading(state) {
            return state.loading
        },
        error(state) {
            return state.error
        }
    }
}