<template>
  <v-card class="ma-5 mt-16 elevation-5 rounded-xl">
    <v-card-title class="text-h5 primary white--text mb-5"
      >סינון<v-spacer></v-spacer
      ><v-icon color="white" @click="addDialog"
        >mdi-filter
        </v-icon>
      </v-card-title>
    <v-card-text class="d-flex flex-wrap" flat tile>
      <div
        v-for="filterColum in HeadersFiltered"
        :key="filterColum.value"
        class="mx-7"
      >
        <v-card
          flat
          max-width="300px"
          v-if="filterColum.filterType === 'contains'"
        >
          <RAutocomplete
            :items="getUniqArrayByKey(filterColum.value, records)"
            :filterColum="filterColum"
            :records="records"
            @containsUpdated="containsUpdated"
          />
        </v-card>
        <v-card
          flat
          max-width="210px"
          v-if="filterColum.filterType === 'minMax'"
        >
          <RMinMax
            :filterColum="filterColum"
            :records="records"
            @containsUpdated="containsUpdated"
          />
        </v-card>
        <v-card flat max-width="310" v-if="filterColum.filterType === 'date'">
          <RDate
            :filterColum="filterColum"
            :records="records"
            @containsUpdated="containsUpdated"
          />
        </v-card>
        <v-card
          flat
          max-width="210"
          v-if="filterColum.filterType === 'checkBox'"
        >
          <RCheckBox
            :filterColum="filterColum"
            :records="records"
            @containsUpdated="containsUpdated"
          />
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>


<script>
import RAutocomplete from "@/components/app/common/Form/RAutocomplete";
import RDate from "@/components/app/common/Form/RDate";
import RMinMax from "@/components/app/common/Form/RMinMax";
import RCheckBox from "@/components/app/common/Form/RCheckBox";

export default {
  props: ["HeadersFiltered", "records"],
  data: () => ({}),
  components: {
    RAutocomplete,
    RDate,
    RMinMax,
    RCheckBox,
  },
  methods: {
    containsUpdated() {
      this.$emit("containsUpdated");
    },
    clearFilter(filterColum) {
      this.$emit("clearFilter",filterColum);
    },
    addDialog(){
      this.$emit("addDialog");
    },
    getUniqArrayByKey(theKey, arr) {
      let mappedArr = arr.map((v) => {
        if (v[theKey] !== null && v[theKey] !== undefined) {
          return v[theKey];
        }
      });
      let mappedArrFiltered = mappedArr
        .filter((val) => {
          return val !== undefined;
        })
        .sort();
      let newArr = this._.uniq(mappedArrFiltered);

      return newArr;
    },
  },
};
</script>

<style>
</style>