import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user.js'
import users from '@/store/modules/users.js'
//mySpace
import mySpaceFormFields from '@/store/modules/mySpace/formFields.js'
import userInfo from '@/store/modules/mySpace/userInfo.js'
import companyInfo from '@/store/modules/mySpace/companyInfo.js'
import companyBillingInfo from '@/store/modules/mySpace/companyBillingInfo.js'
import contactPersons from '@/store/modules/mySpace/contactPersons.js'
import userCars from '@/store/modules/mySpace/userCars.js'
import userDrivers from '@/store/modules/mySpace/userDrivers.js'
import userCommonData from '@/store/modules/userCommonData.js'
import common from '@/store/modules/common.js'
//tables
import lagguageHeaders from '@/store/modules/tables/lagguageHeaders.js'
import lagguageNewPosts from '@/store/modules/tables/lagguage/NewPosts.js'
//porposals
import proposals from '@/store/modules/tables/proposals.js'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    common,
    user,
    users,
    userInfo,
    companyInfo,
    companyBillingInfo,
    contactPersons,
    userCars,
    userDrivers,
    userCommonData,
    mySpaceFormFields,
    lagguageHeaders,
    lagguageNewPosts,
    proposals,
  }
})
