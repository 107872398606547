import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#1565C0',
        secondary: '#5e92f3',
        accent: '#FFD54F',
        error: '#FF7043',
        info: '#26A69A',
      },
    },
  },
});
