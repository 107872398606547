<template>
  <div class="my-0 py-0">
    <v-subheader inset class="my-0 py-0 mb-n3">{{
      filterColum.text
    }}</v-subheader>
    <tr>
      <td>
        <v-btn
          icon
          text
          small
          color="red"
          @click="clearFilter()"
          class="mt-2"
          v-if="notEmptyFilter()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </td>
      <td>
        <v-autocomplete
          color="deep-orange lighten-2"
          dense
          single-line
          :items="items"
          :label="filterColum.label"
          chips
          deletable-chips
          small-chips
          multiple
          v-model="filterColum.search"
          @change="containsUpdated()"
          outlined
          hide-selected
          class="mySelect"
        >
        </v-autocomplete>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: ["filterColum", "items", "records"],
  computed: {
    search() {
      return this.filterColum.search;
    },
  },
  methods: {
    containsUpdated() {
      if (!this.notEmptyFilter()) {
        return;
      }
      this.records.forEach((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        if (this.filterColum.search.includes(i[this.filterColum.value])) {
          i.showfterFiltered[this.filterColum.value] = true;
        } else {
          i.showfterFiltered[this.filterColum.value] = false;
        }
        return i;
      });
      this.$emit("containsUpdated");
    },
    clearFilter() {
      this.records.filter((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        i.showfterFiltered[this.filterColum.value] = true;
        return i;
      });
      this.filterColum.search = [];
      this.$emit("containsUpdated");
    },
    notEmptyFilter() {
      if (
        this.filterColum.search !== "" &&
        this.filterColum.search !== undefined &&
        this.filterColum.search !== null
      ) {
        if (this.filterColum.search.length === 0) {
          return false;
        }
        return true;
      }
    },
  },
  created() {
    // console.log({
    //   filterColum: this.filterColum,
    //   items: this.items,
    //   records: this.records,
    // });
  },
  watch: {
    search() {
      if (this.search.length === 0) {
        this.clearFilter();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mySelect::v-deep .v-chip {
  /* chip background color */
  background-color: #ffe6a0;

  /* chip text color */
  color: gray;

  /* chip X color */
  button {
    color: gray;
  }
}
</style>