<template>
  <div class="my-0 py-0">
    <v-subheader inset class="my-0 py-0 mb-n3">{{
      filterColum.text
    }}</v-subheader>
    <tr>
      <td>
        <v-radio-group
          color="deep-orange lighten-2"
          v-model="filterColum.search"
          row
          @change="containsUpdated()"
          hide-details
          class="mt-1"
        >
          <v-radio color="green" label="יש" :value="true"></v-radio>
          <v-radio color="red" label="אין" :value="false"></v-radio>
          <v-radio
            color="primary"
            label="הכל"
            :value="null"
          ></v-radio>
        </v-radio-group>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: ["filterColum", "records"],
  methods: {
    containsUpdated() {
      this.records.forEach((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        if (
          i[this.filterColum.value] === undefined ||
          this.filterColum.search === null ||
          this.filterColum.search === 2
        ) {
          i.showfterFiltered[this.filterColum.value] = true;
        } else {
          if (i[this.filterColum.value] === this.filterColum.search) {
            i.showfterFiltered[this.filterColum.value] = true;
          } else {
            i.showfterFiltered[this.filterColum.value] = false;
          }
        }
        return i;
      });
      this.$emit("containsUpdated");
    },
  },
};
</script>

<style>
</style>