import firebase from 'firebase/compat/app';

export default {
    state: {
        contactPersons: null,
        // foreignContactPer
    },
    mutations: {
        setContactPersons(state, payload) {
            state.contactPersons = payload
        },
    },
    actions: {
        async getContactPersons(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/contactPersons`).once('value')).val()
            context.commit('setContactPersons', info)
            return info
        },
        async getContactPersonsByID(context,payload) {
            const uid = payload;
            const info = (await firebase.database().ref(`/users/${uid}/contactPersons`).once('value')).val()
            return info
        },
        async getContactPersonByUserByID(context,payload) {
            const info = (await firebase.database().ref(`/users/${payload.uid}/contactPersons/${payload.id}`).once('value')).val()
            return info
        },
        async updateContactPerson(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/contactPersons`).child(`${payload.id}`).update({
                name: payload.name || '',
                role: payload.role || '',
                phone: payload.phone || '',
                email: payload.email || ''
            })
            await context.dispatch('getContactPersons')
        },
        async AddContactPerson(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/contactPersons`).push({
                name: payload.name || '',
                role: payload.role || '',
                phone: payload.phone || '',
                email: payload.email || ''
            })
            await context.dispatch('getContactPersons')
        },
        async removeContactPerson(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/contactPersons/${payload}`).remove()
            await context.dispatch('getContactPersons')
        },
        refreshContactPersons(context) {
            return context.getters.contactPersons
        },
        contactPerson(context, payload){
            let oneContactPerson = context.getters.contactPersons[payload]
            return oneContactPerson
        },
    },
    getters: {
        contactPersons(state) {
            return state.contactPersons || null
        },
    }
}