import firebase from 'firebase/compat/app';

export default {
    state: {
        usersInfo: []
    },
    mutations: {
        usersInfo(state, payload) {
            state.usersInfo = payload
        },
        userInfoByID(state, payload) {
            state.usersInfo.push(payload)
        },
    },
    actions: {
        async getUserByID(context, payload) {
            try {
                let userByID = null;
                let userExist = context.getters.usersInfo.find(v => { if (v.uid === payload.uid) return v })
                if (userExist) {
                    userByID = userExist
                } else {
                    userByID = (await firebase.database().ref(`users/${payload.uid}/info`).once('value')).val()
                    await context.commit('userInfoByID', userByID)
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
    },
    getters: {
        usersInfo(state) {
            return state.usersInfo
        },
        userInfoByID(state, payload) {
            return state.usersInfo[payload]
        },
    }
}