import firebase from 'firebase/compat/app';

export default {
    state: {
        userDrivers: null,
    },
    mutations: {
        setUserDrivers(state, payload) {
            state.userDrivers = payload
        },
    },
    actions: {
        async getUserDrivers(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/userDrivers`).once('value')).val()
            context.commit('setUserDrivers', info)
        },
        async updateUserDriver(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userDrivers`).child(`${payload.id}`).update({
                name: payload.name || '',
                phone: payload.phone || '',
                email: payload.email || '',
                licenseNumber: payload.licenseNumber || ''
            })
            await context.dispatch('getUserDrivers')
        },
        async AddUserDriver(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userDrivers`).push({
                name: payload.name || '',
                phone: payload.phone || '',
                email: payload.email || '',
                licenseNumber: payload.licenseNumber || ''
            })
            await context.dispatch('getUserDrivers')
        },
        async removeUserDriver(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userDrivers/${payload}`).remove()
            await context.dispatch('getUserDrivers')
        },
        refreshUserDrivers(context) {
            return context.getters.userDrivers
        },
        userDriver(context, payload){
            let oneUserDriver = context.getters.userDrivers[payload]
            return oneUserDriver
        },
    },
    getters: {
        userDrivers(state) {
            return state.userDrivers || null
        },
    }
}