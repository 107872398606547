<template>
  <div class="my-0 py-0">
    <v-subheader inset class="my-0 py-0 mb-n3">{{
      filterColum.text
    }}</v-subheader>
    <tr>
      <td>
        <v-btn
          icon
          text
          small
          color="red"
          @click="clearFilter()"
          class="mt-2"
          v-if="notEmptyFilter()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </td>
      <td>
        <v-menu
          color="deep-orange lighten-2"
          v-model="menuOpen"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              single-line
              color="deep-orange lighten-2"
              :value="filterColum.dateMinMax[0]"
              :label="'מ -'"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              outlined
              @change="containsUpdated()"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            color="deep-orange lighten-2"
            v-model="filterColum.dateMinMax[0]"
            @input="dateSelected"
          ></v-date-picker>
        </v-menu>
      </td>
      <td><v-icon small>mdi-minus</v-icon></td>
      <td>
        <v-menu
          color="deep-orange lighten-2"
          v-model="menuOpen2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              single-line
              color="deep-orange lighten-2"
              :value="filterColum.dateMinMax[1]"
              :label="'עד -'"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              outlined
              @change="containsUpdated()"
              readonly
            ></v-text-field>
          </template>
          <v-date-picker
            color="deep-orange lighten-2"
            v-model="filterColum.dateMinMax[1]"
            @input="dateSelected"
          ></v-date-picker>
        </v-menu>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: ["filterColum", "records"],
  data() {
    return {
      menuOpen: false,
      menuOpen2: false,
    };
  },
  methods: {
    containsUpdated() {
      if (!this.notEmptyFilter()) {
        return;
      }
      this.records.forEach((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        let correctDateMax = false;
        let correctDateMin = false;
        if (this.filterColum.dateMinMax[0] !== "") {
          if (!i[this.filterColum.value] || i[this.filterColum.value] === 0) {
            i.showfterFiltered[this.filterColum.value] = false;
          }
          if (
            Date.parse(i[this.filterColum.value]) >=
            Date.parse(this.filterColum.dateMinMax[0])
          ) {
            correctDateMin = true;
          }
        } else {
          correctDateMin = true;
        }
        if (this.filterColum.dateMinMax[1] !== "") {
          if (!i[this.filterColum.value] || i[this.filterColum.value] === 0) {
            i.showfterFiltered[this.filterColum.value] = false;
          }
          if (
            Date.parse(i[this.filterColum.value]) <=
            Date.parse(this.filterColum.dateMinMax[1])
          ) {
            correctDateMax = true;
          }
        } else {
          correctDateMax = true;
        }
        if (
          this.filterColum.dateMinMax[0] === "" &&
          this.filterColum.dateMinMax[1] === ""
        ) {
          i.showfterFiltered[this.filterColum.value] = true;
        }
        if (correctDateMax && correctDateMin) {
          i.showfterFiltered[this.filterColum.value] = true;
        } else {
          i.showfterFiltered[this.filterColum.value] = false;
        }
        return i;
      });
      this.$emit("containsUpdated");
    },
    clearFilter() {
      this.records.filter((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        i.showfterFiltered[this.filterColum.value] = true;
        return i;
      });
      this.filterColum.dateMinMax = ["", ""];
      this.$emit("containsUpdated");
    },
    notEmptyFilter() {
      if (this.filterColum.dateMinMax === undefined) {
        this.filterColum.dateMinMax = ["", ""];
      }
      if (
        this.filterColum.dateMinMax[0] !== "" ||
        this.filterColum.dateMinMax[1] !== ""
      ) {
        return true;
      }
      return false;
    },
    dateSelected() {
      this.menuOpen2 = false;
      this.menuOpen = false;
      this.containsUpdated();
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
</style>