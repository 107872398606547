import firebase from 'firebase/compat/app';

export default {
    state: {
        userCars: null,
    },
    mutations: {
        setUserCars(state, payload) {
            state.userCars = payload
        },
    },
    actions: {
        async getUserCars(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/userCars`).once('value')).val()
            context.commit('setUserCars', info)
        },
        async updateUserCar(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userCars`).child(`${payload.id}`).update({
                carCompany: payload.carCompany || '',
                carModel: payload.carModel || '',
                ctuckType: payload.ctuckType || '',
                coolingOption: payload.coolingOption || '',
                openOption: payload.openOption || '',
                carLength: payload.carLength || '',
                carWidth: payload.carWidth || '',
                carHeight: payload.carHeight || '',
                carVolume: payload.carVolume || '',
                carMaxWeight: payload.carMaxWeight || '',
                extraLicense: payload.extraLicense || ''
            })
            await context.dispatch('getUserCars')
        },
        async AddUserCar(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userCars`).push({
                carCompany: payload.carCompany || '',
                carModel: payload.carModel || '',
                ctuckType: payload.ctuckType || '',
                coolingOption: payload.coolingOption || '',
                openOption: payload.openOption || '',
                carLength: payload.carLength || '',
                carWidth: payload.carWidth || '',
                carHeight: payload.carHeight || '',
                carVolume: payload.carVolume || '',
                carMaxWeight: payload.carMaxWeight || '',
                extraLicense: payload.extraLicense || ''
            })
            await context.dispatch('getUserCars')
        },
        async removeUserCar(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}/userCars/${payload}`).remove()
            await context.dispatch('getUserCars')
        },
        refreshUserCars(context) {
            return context.getters.userCars
        },
        userCar(context, payload){
            let oneUserCar = context.getters.userCars[payload]
            return oneUserCar
        },
    },
    getters: {
        userCars(state) {
            return state.userCars || null
        },
    }
}