import firebase from 'firebase/compat/app';

export default {
    state: {
        companyBillingInfo: null,
    },
    mutations: {
        setCompanyBillingInfo(state, payload) {
            console.log(payload);
            state.companyBillingInfo = payload
        },
        clearCompanyBillingInfo(state) {
            state.companyBillingInfo = null
            state.company = null
        }
    },
    actions: {
        async getCompanyBillingInfo(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/companyBillingInfo`).once('value')).val()
            context.commit('setCompanyBillingInfo', info)
        },
        async updateCompanyBillingInfo(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}`).child('companyBillingInfo').update({
                bank: payload.bank || '',
                branch: payload.branch || '',
                accountNumber: payload.accountNumber || '',
            })
            await context.dispatch('getCompanyBillingInfo')
        },
        refreshCompanyBillingInfo(context) {
            return context.getters.companyBillingInfo
        }
    },
    getters: {
        companyBillingInfo(state) {
            return state.companyBillingInfo || {}
        },
    }
}