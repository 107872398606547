import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from './auth-guard'
import Home from '../views/Home.vue'
import Account from '../views/Account.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'go24' }
  },
  {
    path: '/luggage',
    name: 'luggage',
    component: () => import('@/views/Luggage.vue'),
    meta: { title: 'go24' }
  },
  {
    path: '/account',
    // name: 'account',
    component: Account,
    meta: { title: 'go24 | account' },
    redirect: {name:'MySpace'},
    beforeEnter: AuthGuard,
    children:[
      // {
      //   path: '',
      //   name: 'MySpace',
      //   component: MySpace,
      //   meta: { title: 'go24' },
      //   beforeEnter: AuthGuard
      // },
      {
        path: '',
        component: () => import('@/views/account/MySpace.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MySpace',
        name: 'MySpace',
        component: () => import('@/views/account/MySpace.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyLuggage',
        name: 'MyLuggage',
        component: () => import('@/views/account/MyLuggage.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyLuggage2',
        name: 'MyLuggage2',
        component: () => import('@/views/account/MyLuggage2.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyTrucking',
        name: 'MyTrucking',
        component: () => import('@/views/account/MyTrucking.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      },
      {
        path: 'MyChats',
        name: 'MyChats',
        component: () => import('@/views/account/MyChats.vue'),
        meta: { title: 'go24' },
        beforeEnter: AuthGuard
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: { title: 'go24' },
    beforeEnter: AuthGuard
  }

]

const router = new VueRouter({
  mode: 'history',
  // baseUrl:'',
  base: process.env.BASE_URL,
  // base: '',
  routes
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
