var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonName)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}},[_c('div',[(!_vm.loading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"primary mb-9"},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.buttonName))]),_c('v-spacer'),(_vm.newPostType_selected && !_vm.editMode)?_c('v-icon',{staticClass:"mx-3",attrs:{"color":"white"},on:{"click":_vm.goBack}},[_vm._v(" mdi-arrow-right")]):_vm._e(),_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.closeDialog}},[_vm._v(" mdi-close")])],1),(_vm.newPostType_selected || _vm.editMode)?_c('div',[_c('v-card-text',{staticClass:"d-flex flex-wrap",attrs:{"flat":"","tile":""}},[_c('v-form',{ref:"form",staticClass:"d-flex flex-wrap",attrs:{"flat":"","tile":""}},[_vm._l((_vm.fieldsFiltered),function(field,index){return _c('div',{key:index,staticClass:"mx-7 my-0 py-0"},[(
                  field.inputFieldType === 'date' && _vm.isIncludePostType(field)
                )?_c('v-card',{attrs:{"flat":"","max-width":"230px"}},[_c('v-menu',{attrs:{"color":"deep-orange lighten-2","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","color":"deep-orange lighten-2","value":_vm.values[field.value],"label":field.text,"prepend-inner-icon":"mdi-calendar","outlined":"","rules":field.inputRequired ? _vm.textRole : []}},'v-text-field',attrs,false),on))]}}],null,true)},[_c('v-date-picker',{attrs:{"color":"deep-orange lighten-2"},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1)],1):_vm._e(),(
                  field.inputFieldType === 'text' && _vm.isIncludePostType(field)
                )?_c('v-card',{staticClass:"mx-1",attrs:{"flat":"","max-width":"230px"}},[_c('v-text-field',{attrs:{"color":"deep-orange lighten-2","label":field.text,"disabled":!_vm.editable,"type":'text'},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1):_vm._e(),(
                  field.inputFieldType === 'number' &&
                  _vm.isIncludePostType(field)
                )?_c('v-card',{attrs:{"flat":"","width":"230px"}},[_c('v-text-field',{attrs:{"color":"deep-orange lighten-2","dense":"","outlined":"","label":field.text,"disabled":!_vm.editable,"type":'number',"rules":field.inputRequired ? _vm.textRole : []},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1):_vm._e(),(
                  field.inputFieldType === 'checkBox' &&
                  _vm.isIncludePostType(field)
                )?_c('v-card',{attrs:{"flat":""}},[_c('v-checkbox',{attrs:{"color":"deep-orange lighten-2","label":field.text,"disabled":!_vm.editable,"value":false},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1):_vm._e(),(
                  field.inputFieldType === 'select' &&
                  _vm.isIncludePostType(field)
                )?_c('v-card',{attrs:{"flat":"","max-width":"230px"}},[_c('autocomplete',{attrs:{"text":field.text,"selectOptions":field.selectOptions,"inputRequired":field.inputRequired,"values":_vm.values,"field":field},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1):_vm._e(),(
                  field.inputFieldType === 'textArea' &&
                  _vm.isIncludePostType(field)
                )?_c('v-card',{attrs:{"flat":"","width":"800px"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","color":"deep-orange lighten-2","label":field.text,"disabled":!_vm.editable,"values":_vm.values},model:{value:(_vm.values[field.value]),callback:function ($$v) {_vm.$set(_vm.values, field.value, $$v)},expression:"values[field.value]"}})],1):_vm._e()],1)}),(_vm.contactPersons)?_c('div',{staticClass:"mx-7 my-0 py-0"},[_c('v-card',{attrs:{"flat":"","max-width":"230px"}},[_c('autocomplete',{attrs:{"text":'איש קשר',"selectOptions":'contactPerson',"inputRequired":false,"preloadItems":_vm.contactPersonsUID,"values":_vm.values,"field":{ value: 'contactPerson' }},model:{value:(_vm.values.contactPerson),callback:function ($$v) {_vm.$set(_vm.values, "contactPerson", $$v)},expression:"values.contactPerson"}})],1)],1):_vm._e()],2)],1),_c('v-card-actions',[(!_vm.editMode)?_c('div',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.publishClick('NewPosts')}}},[_vm._v(" פרסם ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.publishClick('Tamples')}}},[_vm._v(" טיוטה ")]),_c('v-btn',{attrs:{"color":"deep-orange lighten-2","text":""},on:{"click":function($event){return _vm.publishClick('Archives')}}},[_vm._v(" ארכיון ")])],1):_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" שמור ")])],1)])],1):_c('div',[_c('v-card-text',{staticClass:"d-flex flex-wrap",attrs:{"flat":"","tile":""}},_vm._l((_vm.newPostType),function(postType){return _c('v-col',{key:postType,attrs:{"cols":"12","lg":"4","sm":"6","xl":"3"}},[_c('v-btn',{attrs:{"elevation":"0","color":"primary","width":"100%","height":"20vh"},on:{"click":function($event){return _vm.typeSelected(postType)}}},[_vm._v(_vm._s(postType))])],1)}),1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }