var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-dialog',{key:_vm.loading,attrs:{"scrollable":"","persistent":"","max-width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-left white--text",attrs:{"color":"primary","disabled":_vm.proposals.length === 0}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.title)+" ("+_vm._s(_vm.proposals.length)+") ")])]}}],null,false,1898977790),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"elevation-9 rounded-xl"},[_c('v-card-title',{staticClass:"primary mb-5",staticStyle:{"position":"sticky"}},[_c('span',{staticClass:"white--text mx-1"},[_vm._v(" "+_vm._s(_vm.title)+" ("+_vm._s(_vm.proposals.length)+")")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("mdi-close ")])],1),_c('v-card-text',{attrs:{"flat":"","tile":""}},_vm._l((_vm.proposals),function(porposal){return _c('div',{key:porposal.id},[_c('v-card',{staticClass:"mx-1 my-5 elevation-9 rounded-xl"},[(porposal.proposalerName)?_c('v-card-title',{class:{
              'deep-orange': true,
              'lighten-2': !porposal.agreed,
              'mb-5': true,
            }},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(porposal.proposalerName.name))]),(porposal.agreed)?_c('span',{staticClass:"white--text mx-1"},[_vm._v(" (אושר)")]):_vm._e(),(porposal.timeStamp)?_c('span',{staticClass:"caption text--secondary mx-2 mt-1"},[_vm._v(" "+_vm._s(_vm._f("date")(porposal.timeStamp,"datetime"))+" ")]):_vm._e(),_c('v-spacer'),(_vm.post.postState != 'inProgress' && !_vm.trucking)?_c('v-btn',{staticClass:"primary rounded-lg mx-3 white--text",attrs:{"color":"white"},on:{"click":function($event){return _vm.agreeToProposal(porposal)}}},[_vm._v(" אשר עסקה "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check-bold")])],1):_vm._e(),(!_vm.trucking)?_c('RFormNewMessage',{attrs:{"buttonName":'שלח הודעה',"disabled":porposal.proposalerName.uid === _vm.UID,"to":porposal.proposalerName.uid}}):_vm._e()],1):_vm._e(),(porposal.proposalerName)?_c('v-card-text',{attrs:{"flat":"","tile":""}},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" שם: "+_vm._s(porposal.proposalerName.name)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" דוא\"ל: "+_vm._s(porposal.proposalerName.email)+" ")]),_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" טלפון: "+_vm._s(porposal.proposalerName.phoneNumber)+" ")]),(porposal.comments)?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" הערות: "+_vm._s(porposal.comments)+" ")]):_vm._e(),(porposal.price)?_c('div',{staticClass:"text-subtitle-1"},[_vm._v(" מחיר שהציעו: "+_vm._s(porposal.price)+" "),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-currency-ils")])],1):_vm._e()]):_vm._e(),_c('v-card-actions',[_c('v-spacer'),(_vm.post.postState === 'inProgress')?_c('v-btn',{staticClass:"mx-2 mb-4",attrs:{"dark":"","color":"deep-orange lighten-2"},on:{"click":function($event){return _vm.relocateTo('done')}}},[_vm._v(" בוצע ")]):_vm._e()],1)],1)],1)}),0)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }