<template>
  <v-card>
    <v-card-title style="position:sticky" class="text-h5 primary white--text">
      {{ title }}
      <v-spacer></v-spacer>
      <v-icon color="white" left @click="$emit('Cancel')"> mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item-group v-model="itemSelected" multiple>
          <template v-for="(item, i) in itemTitles()">
            <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

            <v-list-item dense v-else :key="`item-${i}`" :value="item">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-checkbox
                    dense
                    :input-value="active"
                    color="deep-orange lighten-2"
                  ></v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="Agree"> שמור </v-btn>
      <v-btn color="red darken-1" text @click="$emit('Cancel')"> ביטול </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Home",
  props: ["id", "items", "title"],
  data: () => ({
    itemSelected: [],
    // model: [],
  }),
  methods: {
    Agree() {
      this.$emit("Agree", this.itemSelected);
    },
    itemTitles() {
      var titles = this.items.map((obj) => {
        return obj.text;
      });
      return titles;
    },
    itemSelectedMethod() {
      var titles = this.items.map((obj) => {
        if (obj.selected === true) {
          return obj.text;
        }
      });
      return titles;
    },
  },
  computed: {
    itemSelectedComputed() {
      return this.itemSelectedMethod();
    },
  },
  created() {
    this.itemSelected = this.itemSelectedMethod();
  },
  // components: {
  // },
};
</script>