import firebase from 'firebase/compat/app';

export default {
    state: {
        lagguageHeaders: null,
        citiesList: null,
        lagguageType: null,
        carType: null,
        packegeType: null,
        adrType: null,
        payMethod: null,
        newPostType: null,
    },
    mutations: {
        lagguageHeaders(state, payload) {
            state.lagguageHeaders = payload
        },
        citiesList(state, payload) {
            state.citiesList = payload
        },
        lagguageType(state, payload) {
            state.lagguageType = payload
        },
        carType(state, payload) {
            state.carType = payload
        },
        packegeType(state, payload) {
            state.packegeType = payload
        },
        adrType(state, payload) {
            state.adrType = payload
        },
        payMethod(state, payload) {
            state.payMethod = payload
        },
        newPostType(state, payload) {
            state.newPostType = payload
        },
    },
    actions: {
        async citiesList(context) {
            if (!context.getters.citiesList) {
                let citiesListNew = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/citiesList`).once('value')).val()
                await context.commit('citiesList', citiesListNew)
                console.log('cities loaded!!!');
            }
            return context.getters.citiesList
        },
        async lagguageType(context) {
            if (!context.getters.lagguageType) {
                let lagguageTypeNew = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/lagguageType`).once('value')).val()
                await context.commit('lagguageType', lagguageTypeNew)
                // console.log('cities loaded!!!');
            }
            return context.getters.lagguageType
        },
        async carType(context) {
            if (!context.getters.carType) {
                let carTypeNew = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/carType`).once('value')).val()
                await context.commit('carType', carTypeNew)
                // console.log('cities loaded!!!');
            }
            return context.getters.carType
        },
        async packegeType(context) {
            if (!context.getters.packegeType) {
                let packegeTypeNew = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/packegeType`).once('value')).val()
                await context.commit('packegeType', packegeTypeNew)
                // console.log('cities loaded!!!');
            }
            return context.getters.packegeType
        },
        
        async adrType(context) {
            if (!context.getters.adrType) {
                let adrType = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/adrType`).once('value')).val()
                await context.commit('adrType', adrType)
                // console.log('cities loaded!!!');
            }
            return context.getters.adrType
        },
        async payMethod(context) {
            if (!context.getters.payMethod) {
                let payMethod = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/payMethod`).once('value')).val()
                await context.commit('payMethod', payMethod)
                // console.log('cities loaded!!!');
            }
            return context.getters.payMethod
        },
        async newPostType(context) {
            if (!context.getters.payMethod) {
                let newPostType = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/newPostType`).once('value')).val()
                await context.commit('newPostType', newPostType)
                // console.log('cities loaded!!!');
            }
            return context.getters.newPostType
        },
        async lagguageHeaders(context) {
            // let citiesList = await context.dispatch('citiesList')
            // let lagguageType = await context.dispatch('lagguageType')
            // let carType = await context.dispatch('carType')
            // let packegeType = await context.dispatch('packegeType')
            // let adrType = await context.dispatch('adrType')
            if (!context.getters.lagguageHeaders) {
                let lagguageHeadersNew = (await firebase.database().ref(`tablesCommonData/lagguageHeaders/lagguageHeaders`).once('value')).val()
                // lagguageHeadersNew = lagguageHeadersNew.map(v => {
                    // if (v.selectOptions === 'citiesList') {
                    //     v.selectOptions = citiesList
                    // }
                    // if (v.selectOptions === 'lagguageType') {
                    //     v.selectOptions = lagguageType
                    // }
                    // if (v.selectOptions === 'carType') {
                    //     v.selectOptions = carType
                    // }
                    // if (v.selectOptions === 'packegeType') {
                    //     v.selectOptions = packegeType
                    // }
                    // if (v.selectOptions === 'adrType') {
                    //     v.selectOptions = adrType
                    // }
                //     return v
                // })
                await context.commit('lagguageHeaders', lagguageHeadersNew)
            }
            return context.getters.lagguageHeaders
        },
    },
    getters: {
        lagguageHeaders(state) {
            return state.lagguageHeaders
        },
        citiesList(state) {
            return state.citiesList
        },
        lagguageType(state) {
            return state.lagguageType
        },
        carType(state) {
            return state.carType
        },
        packegeType(state) {
            return state.packegeType
        },
        adrType(state) {
            return state.adrType
        },
        payMethod(state) {
            return state.payMethod
        },
        newPostType(state) {
            return state.newPostType
        },
    }
}