<template>
  <v-dialog
    v-if="!loading"
    scrollable
    persistent
    v-model="showDialog"
    max-width="900"
    :key="loading"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        class="float-left white--text"
        v-bind="attrs"
        v-on="on"
        :disabled="proposals.length === 0"
      >
        {{ title }} ({{ proposals.length }})
      </v-btn>
    </template>
    <v-card class="elevation-9 rounded-xl">
      <v-card-title style="position: sticky" class="primary mb-5">
        <span class="white--text mx-1">
          {{ title }} ({{ proposals.length }})</span
        >
        <v-spacer></v-spacer>
        <v-icon color="white" @click="showDialog = false">mdi-close </v-icon>
      </v-card-title>
      <v-card-text flat tile>
        <div v-for="porposal in proposals" :key="porposal.id">
          <v-card class="mx-1 my-5 elevation-9 rounded-xl">
            <v-card-title
              :class="{
                'deep-orange': true,
                'lighten-2': !porposal.agreed,
                'mb-5': true,
              }"
              v-if="porposal.proposalerName"
            >
              <span class="white--text">{{
                porposal.proposalerName.name
              }}</span>
              <span v-if="porposal.agreed" class="white--text mx-1">
                (אושר)</span
              >

              <span
                v-if="porposal.timeStamp"
                class="caption text--secondary mx-2 mt-1"
              >
                {{ porposal.timeStamp | date("datetime") }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="primary rounded-lg mx-3 white--text"
                color="white"
                @click="agreeToProposal(porposal)"
                v-if="post.postState != 'inProgress' && !trucking"
              >
                אשר עסקה
                <v-icon right>mdi-check-bold</v-icon>
              </v-btn>
              <RFormNewMessage
                v-if="!trucking"
                :buttonName="'שלח הודעה'"
                :disabled="porposal.proposalerName.uid === UID"
                :to="porposal.proposalerName.uid"
              />
            </v-card-title>
            <v-card-text flat tile v-if="porposal.proposalerName">
              <div class="text-subtitle-1">
                שם: {{ porposal.proposalerName.name }}
              </div>
              <div class="text-subtitle-1">
                דוא"ל: {{ porposal.proposalerName.email }}
              </div>
              <div class="text-subtitle-1">
                טלפון: {{ porposal.proposalerName.phoneNumber }}
              </div>
              <div class="text-subtitle-1" v-if="porposal.comments">
                הערות:
                {{ porposal.comments }}
              </div>
              <div class="text-subtitle-1" v-if="porposal.price">
                מחיר שהציעו:
                {{ porposal.price }}
                <v-icon left>mdi-currency-ils</v-icon>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                class="mx-2 mb-4"
                color="deep-orange lighten-2"
                v-if="post.postState === 'inProgress'"
                @click="relocateTo('done')"
              >
                בוצע
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import fire from "@/main";

import RFormNewMessage from "@/components/app/common/Form/RFormNewMessage";

export default {
  props: {
    post: {},
    trucking: {
      default: false,
    },
    title: {
      default: "הצעות",
    },
  },
  data() {
    return {
      loading: true,
      showDialog: false,
      showEditDialog: false,
      proposals: [],
    };
  },
  computed: {
    ...mapGetters(["UID"]),
  },
  async created() {
    await this.loadPorposals();
  },
  methods: {
    async agreeToProposal(payload) {
      // console.log({UID:this.UID,payload:payload});
      await this.sendMsg(payload);
      await this.updatePorposal(payload);
      await this.relocateTo("inProgress");
    },
    async relocateTo(target) {
      delete this.post.showfterFiltered;
      await fire.database().ref(`recors/lagguage/${target}`).push(this.post);
      await this.deleteItem();
    },
    async deleteItem() {
      delete this.post.showfterFiltered;
      await fire
        .database()
        .ref(`recors/lagguage/${this.post.postState}/${this.post.currentID}`)
        .remove();
    },
    async updatePorposal(payload) {
      delete payload.proposalerName;
      payload.agreed = true;
      let id = payload.id;
      delete payload.id;
      await fire
        .database()
        .ref(`recors/lagguage/proposals/`)
        .child(id)
        .update(payload);
    },
    async sendMsg(payload) {
      let new_msg = {
        from: this.UID,
        to: payload.proposalerUID,
        msg_content: `כיבלת.ה אישור עסקה להודעה מס '${payload.postUID}'.'`,
        unread: true,
        timestamp: Date(),
      };
      await fire.database().ref("messages").push(new_msg);
    },
    async loadPorposals() {
      let postUID = this.post.id;
      let viewMessage = this;
      let itemsRef = await fire
        .database()
        .ref(`recors/lagguage/proposals/`)
        .orderByChild("postUID")
        .equalTo(postUID);

      await itemsRef.on("value", async (snapshot) => {
        viewMessage.loading = false;
        let data = snapshot.val();
        if (data) {
          this.loading = true;
          viewMessage.proposals.length = 0;
          let arr_tmp = [];
          let keys_arr = Object.keys(data);
          for (let key of keys_arr) {
            let arr_tmp_ready = {
              id: key,
              ...data[key],
            };
            console.log({ arr_tmp_ready: arr_tmp_ready });
            arr_tmp_ready.proposalerName = null;
            arr_tmp_ready.proposalerName = await this.$store.dispatch(
              "getUserByID",
              { uid: arr_tmp_ready.proposalerUID }
            );
            if (this.trucking) {
              if (arr_tmp_ready.proposalerUID === this.UID) {
                arr_tmp.push(arr_tmp_ready);
              }
            } else {
              arr_tmp.push(arr_tmp_ready);
            }
          }
          viewMessage.proposals = arr_tmp;
          viewMessage.loading = false;
        }
      });
    },
  },
  components: {
    RFormNewMessage,
  },
};
</script>

<style>
</style>