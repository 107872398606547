import firebase from 'firebase/compat/app';

export default {
    state: {
        companyInfo: null,
    },
    mutations: {
        setCompanyInfo(state, payload) {
            console.log(payload);
            state.companyInfo = payload
        },
        clearCompanyInfo(state) {
            state.companyInfo = null
            state.company = null
        }
    },
    actions: {
        async getCompanyInfo(context) {
            const uid = await context.dispatch('getUID');
            const info = (await firebase.database().ref(`/users/${uid}/companyInfo`).once('value')).val()
            context.commit('setCompanyInfo', info)
        },
        async updateCompanyInfo(context, payload) {
            const uid = await context.dispatch('getUID');
            await firebase.database().ref(`users/${uid}`).child('companyInfo').update({
                companyName: payload.companyName || '',
                companyType: payload.companyType || '',
                activityType: payload.activityType || '',
                companyID: payload.companyID || '',
                registrationDate: payload.registrationDate || '',
                phone: payload.phone || '',
                webPage: payload.webPage || '',
                email: payload.email || '',
                phisicalAdress: payload.phisicalAdress || ''
            })
            await context.dispatch('getCompanyInfo')
        },
        refreshCompanyInfo(context) {
            return context.getters.companyInfo
        }
    },
    getters: {
        companyInfo(state) {
            return state.companyInfo || null
        },
    }
}