import firebase from 'firebase/compat/app';

export default {
    state: {
        contactPersonsFields: null,
        userCarsFields: null,
        userDriversFields: null,
    },
    mutations: {
        contactPersonsFields(state, payload) {
            state.contactPersonsFields = payload
        },
        userCarsFields(state, payload) {
            state.userCarsFields = payload
        },
        userDriversFields(state, payload) {
            state.userDriversFields = payload
        },
    },
    actions: {
        async contactPersonsFields(context) {
            if (!context.getters.contactPersonsFields) {
                let contactPersonsFieldsNew = (await firebase.database().ref(`formFields/MySpace/contactPersons`).once('value')).val()
                await context.commit('contactPersonsFields', contactPersonsFieldsNew)
            }
            return context.getters.contactPersonsFields
        },
        async userCarsFields(context) {
            if (!context.getters.userCarsFields) {
                let userCarsFieldsNew = (await firebase.database().ref(`formFields/MySpace/userCars`).once('value')).val()
                await context.commit('userCarsFields', userCarsFieldsNew)
            }
            return context.getters.userCarsFields
        },
        async userDriversFields(context) {
            if (!context.getters.userDriversFields) {
                let userDriversFieldsNew = (await firebase.database().ref(`formFields/MySpace/userDrivers`).once('value')).val()
                await context.commit('userDriversFields', userDriversFieldsNew)
            }
            return context.getters.userDriversFields
        },
    },
    getters: {
        contactPersonsFields(state) {
            return state.contactPersonsFields
        },
        userCarsFields(state) {
            return state.userCarsFields
        },
        userDriversFields(state) {
            return state.userDriversFields
        },
    }
}