import firebase from 'firebase/compat/app';
import { updateEmail, updatePassword } from "firebase/auth";

export default {
    state: {
        user: null,
        UID: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setUID(state, payload) {
            state.UID = payload
        },
    },
    actions: {
        async registerUser(context, payload) {
            try {
                await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
                let user = firebase.auth().currentUser
                context.commit('setUser', user)
                await firebase.database().ref(`users/${user.uid}/info`).set({
                    createdAt: Date(),
                    uid: user.uid,
                    email: user.email,
                    name: payload.name,
                    userRole: payload.userRole,
                    bussinesNumber: payload.bussinesNumber,
                    secondEmail: payload.secondEmail,
                    phoneNumber: payload.phoneNumber
                })
                const uid = await context.dispatch('getUID');
                const info = (await firebase.database().ref(`/users/${uid}/info`).once('value')).val()
                context.commit('setUserInfo', info)
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
        async login(context, payload) {
            try {
                await firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
                let user = firebase.auth().currentUser
                context.commit('setUser', user)
                // await context.dispatch('getUserInfo')
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }
        },
        autoLogin(context, payload) {
            context.commit('setUser', payload)
            // context.dispatch('getUserInfo')
            context.dispatch('getUserRoles')
        },
        async updateUserAuthMail(context, payload) {
            if (firebase.auth().currentUser.email !== payload) {
                try {
                    let auth = firebase.auth();
                    await updateEmail(auth.currentUser, payload)
                } catch (error) {
                    context.dispatch('clearError')
                    context.dispatch('setError', error.message)
                    throw error
                }
            }
        },
        async updateUserAuthPassword(context, payload) {
            try {
                let auth = firebase.auth();
                await updatePassword(auth.currentUser, payload)
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }
        },
        async logOut(context) {
            await firebase.auth().signOut();
            await context.commit('clearUserInfo')
            context.state.user=null;
        },
        getUID(context) {
            const uid = firebase.auth().currentUser
            if (uid) context.commit('setUID', uid.uid)
            return uid ? uid.uid : null
        },
    },
    getters: {
        user(state) {
            return state.user || null
        },
        UID(state) {
            return state.UID || null
        },
    }
}