import firebase from 'firebase/compat/app';

export default {
    state: {
        userRoles: null,
        companyTypes: null,
        activityTypes: null,
        carCompanies: null
    },
    mutations: {
        setUserRoles(state, payload) {
            state.userRoles = payload
        },
        setCompanyTypes(state, payload) {
            state.companyTypes = payload
        },
        setActivityTypes(state, payload) {
            state.activityTypes = payload
        },
        setCarCompanies(state, payload) {
            state.activityTypes = payload
        },
    },
    actions: {
        async getUserRoles(context) {
            if (!context.getters.userRoles) {
                let userRolesNew = (await firebase.database().ref(`userCommonData/userRoles`).once('value')).val()
                await context.commit('setUserRoles', userRolesNew)
            }
            return context.getters.userRoles
        },
        async getCompanyTypes(context) {
            if (!context.getters.companyTypes) {
                let companyTypesNew = (await firebase.database().ref(`userCommonData/companyTypes`).once('value')).val()
                await context.commit('setCompanyTypes', companyTypesNew)
            }
            return context.getters.companyTypes
        }, 
        async getActivityTypes(context) {
            if (!context.getters.activityTypes) {
                let activityTypesNew = (await firebase.database().ref(`userCommonData/activityTypes`).once('value')).val()
                await context.commit('setActivityTypes', activityTypesNew)
            }
            return context.getters.activityTypes
        },
        async getCarCompanies(context) {
            if (!context.getters.carCompanies) {
                let carCompaniesNew = (await firebase.database().ref(`userCommonData/carCompanies`).once('value')).val()
                await context.commit('setCarCompanies', carCompaniesNew)
            }
            return context.getters.carCompanies
        },
    },
    getters: {
        userRoles(state) {
            return state.userRoles
        },
        companyTypes(state) {
            return state.companyTypes
        },
        activityTypes(state) {
            return state.activityTypes
        },
        carCompanies(state) {
            return state.carCompanies
        }
    }
}