<template>
  <v-autocomplete
    color="deep-orange lighten-2"
    dense
    outlined
    :label="text"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    v-model="value"
    :rules="inputRequired ? textRole : []"
    @click="querySelections()"
    @focus="querySelections()"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    text: {
      default: "",
    },
    selectOptions: {
      default: "",
    },
    inputRequired: {},
    field: {},
    values: {},
    preloadItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      value: null,
      items: [],
      search: null,
      loading: false,
      textRole: [(v) => !!v || "צריך למלות"],
    };
  },
  async created(){
    if(this.values[this.field.value]){
      let val = this.values[this.field.value]
      await this.querySelections()
      if(this.preloadItems.length == 0){
        this.value=val
      } else {
        let name = Object.values(this.preloadItems).find((el) => el.id == val);
        if (name) {
          this.value = name.name;
        }
      }
      
    }
    console.log(this.field.value+ " - " +this.values[this.field.value]);
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    value(val) {
      if (this.preloadItems.length == 0) {
        this.values[this.field.value] = val;
      } else {
        let id = Object.values(this.preloadItems).find((el) => el.name == val);
        if (id) {
          this.values[this.field.value] = id.id;
        }
      }
    },
  },
  methods: {
    async querySelections() {
      if (this.selectOptions === "") {
        return;
      }
      this.loading = true;
      if (this.preloadItems.length == 0) {
        if (this.items.length == 0) {
          let newItems = await this.$store.dispatch(this.selectOptions);
          this.items = JSON.parse(JSON.stringify(newItems));
        }
      } else {
        this.items = Object.values(this.preloadItems).map((v) => {
          return v.name;
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style>
</style>