<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer padless class="transparent mt-16">
      <v-container>
        <v-row>
          <v-col class="" cols="12">
            <v-img position="right" src="@/assets/logo_go_color.png" contain max-height="34"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="" cols="4">
            <span>מידע חיוני</span>
          </v-col>
          <v-col class="" cols="4">
            <div><span class="caption mx-0">עלינו</span></div>
            <div><span class="caption mx-0">שיתוף פעולה</span></div>
            <div><span class="caption mx-0">בלוג</span></div>
            <div><span class="caption mx-0">צור קשר</span></div>
          </v-col>
          <v-col class="" cols="4">
            <div><span class="caption">mail@mail.com</span></div>
            <div><span class="caption">053-2776648</span></div>
            <div><span class="caption">go24.co.il</span></div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <template v-if="error">
      <v-snackbar
        :value="true"
        :multi-line="true"
        :timeout="5000"
        @input="closeError"
      >
        {{ error }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @input="closeError">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
  computed: {
    error() {
      return this.$store.getters.error;
    },
  },
  methods: {
    closeError() {
      this.$store.dispatch("clearError");
    },
  },
};
</script>
