import store from '@/store/index'

export default async function (to, from, next) {
    if (await store.dispatch('getUID')) {
        if (to.path === '/account') {
            next('/account/MySpace')
        } 
        if (to.path === '/register') {
            next('/')
        } else {
            next()
        }
    } else {
        console.log('not logged in');
        if (to.path !== '/register' && to.path !== '/login') {
            next('/login')
        } else {
            next()
        }
    }
}