import Vue from 'vue'
import App from './App.vue'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database'

import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

//filters
import dateFilter from '@/filters/date-filter'


/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.filter('date', dateFilter)

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBe6eYg7PC3tbVF6YxgBVJ9T3q4MzrlDDc",
  authDomain: "mythical-pod-582.firebaseapp.com",
  databaseURL: "https://mythical-pod-582-default-rtdb.firebaseio.com",
  projectId: "mythical-pod-582",
  storageBucket: "mythical-pod-582.appspot.com",
  messagingSenderId: "66874463517",
  appId: "1:66874463517:web:909a31138e94f38d5b3df4",
  measurementId: "G-4KXENXP6WT"
};

var fire = firebase.initializeApp(firebaseConfig);

let app;


firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
      beforeCreate() {
        // firebase.auth().onAuthStateChanged(user => {
        if (user) {
          console.log('logged in already');
          this.$store.dispatch("autoLogin", user)
        }
        // })
      },
    }).$mount('#app')
  }

})

export default fire;
