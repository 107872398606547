import firebase from 'firebase/compat/app';

export default {
    state: {
        proposals: []
    },
    mutations: {
        proposals(state, payload) {
            state.proposals = payload
        },
        pushProposal(state, payload) {
            for (let i = 0; i < payload.length; i++) {
                // console.log({"in":"pushProposal",i:payload[i].id});
                let exist = state.proposals.find(v => { if (v.id === payload[i].id) return v })
                if (!exist) {
                    state.proposals.push(payload[i])
                }
            }

        },
    },
    actions: {
        async getProposalByPublisherID(context, payload) {
            try {
                let userByID = null;
                let userExist = null
                if (context.getters.proposals.length > 0) {
                    userExist = context.getters.proposals.find(v => { if (v.publisherUID === payload.publisherUID) return v })
                }
                if (userExist) {
                    console.log("already exits ByPublisherID");
                    userByID = userExist
                    console.log(userByID);
                } else {
                    userByID = (await firebase.database().ref(`recors/lagguage/proposals/`).orderByChild("publisherUID").equalTo(payload.publisherUID).once('value')).val()
                    if (userByID) {
                        userByID = Object.keys(userByID).map(key => ({ ...userByID[key], id: key }))
                        console.log("new porposels ByPublisherID");
                        console.log(userByID);
                        await context.commit('pushProposal', userByID)
                    }
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
        async getProposalByPproposalerID(context, payload) {
            try {
                let userByID = null;
                let userExist = null
                // if (context.getters.proposals.length > 0) {
                //     userExist = context.getters.proposals.find(v => { if (v.proposalerUID === payload.proposalerUID) return v })
                // }
                if (userExist) {
                    console.log("already exits ByPproposalerID");
                    userByID = userExist
                    console.log(userByID);
                } else {
                    userByID = (await firebase.database().ref(`recors/lagguage/proposals/`).orderByChild("proposalerUID").equalTo(payload.proposalerUID).once('value')).val()
                    if (userByID) {
                        userByID = Object.keys(userByID).map(key => ({ ...userByID[key], id: key }))
                        console.log("new porposels ByPproposalerID");
                        console.log(userByID);
                        await context.commit('pushProposal', userByID)
                    }
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
        async getProposalByPostID(context, payload) {
            try {
                let userByID = null;
                let userExist = null
                if (context.getters.proposals.length > 0) {
                    userExist = context.getters.proposals.find(v => { if (v.postUID === payload.postUID) return v })
                }
                if (userExist && !payload.forceUpdate) {
                    console.log("already exits ByPublisherID");
                    userByID = userExist
                    // console.log(userByID);
                } else {
                    userByID = (await firebase.database().ref(`recors/lagguage/proposals/`).orderByChild("postUID").equalTo(payload.postUID).once('value')).val()
                    if (userByID) {
                        userByID = Object.keys(userByID).map(key => ({ ...userByID[key], id: key }))
                        console.log("new porposels getProposalByPostID");
                        // console.log(userByID);
                        await context.commit('pushProposal', userByID)
                    }
                }
                return userByID
            } catch (error) {
                context.dispatch('clearError')
                context.dispatch('setError', error.message)
                throw error
            }

        },
        async addPorposal(context, payload) {
            await firebase.database().ref(`recors/lagguage/proposals/`).push(
                payload
            )
            await context.commit('pushProposal', payload)
        },
        async updatePorposal(context, payload) {
            let id = payload.id
            delete payload.id;
            await firebase.database().ref(`recors/lagguage/proposals/`).child(id).update(payload)
        }
    },
    getters: {
        proposals(state) {
            return state.proposals
        },
        proposalByID(state, payload) {
            return state.proposals[payload]
        },
    }
}