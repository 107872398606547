<template>
  <v-dialog persistent v-model="showDialog" max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2 white--text"
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ buttonName }}
        <v-icon v-if="!disabled" right>mdi-email-fast</v-icon>
      </v-btn>
    </template>
    <div>
      <v-card flat>
        <v-card-title class="text-h5 primary white--text mb-5">
          הצעה חדשה
          <v-spacer></v-spacer>
          <v-icon color="white" @click="showDialog = false"> mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="d-flex flex-wrap" flat tile>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :type="'number'"
                  outlined
                  label="מחיר שאתה מציעה"
                  append-icon="mdi-currency-ils"
                  v-model="price"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="הערות"
                  v-model="comments"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" class="white--text" color="primary">
            שלח הצעה
            <v-icon v-if="!disabled" right>mdi-email-fast</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    buttonName: {
      default: "edit",
    },
    disabled: {
      default: false,
    },
    startPrice: {
      default: 0,
    },
    to: {
      default: "111",
    },
    postID: {
      default: "111",
    },
  },
  data() {
    return {
      showDialog: false,
      price: 0,
      comments: "",
    };
  },
  computed: {
    ...mapGetters(["UID"]),
  },
  created() {
    this.price = this.startPrice;
  },
  methods: {
    async save() {
      await this.sendMsg();
      this.$emit("makeProposal", {
        comments: this.comments,
        price: this.price,
      });
      console.log({ comments: this.comments, price: this.price });
      this.showDialog = false;
    },
    async sendMsg() {
      let new_msg = {
        from: this.UID,
        to: this.to,
        msg_content: `כיבלת.ה הצעת הובלה ממני. מודעה מס '${this.postID}'. | 
        הערות: '${this.comments}' | 
        מחיר: '${this.price}ש"ח'`,
        unread: true,
        timestamp: Date(),
      };
      await fire.database().ref("messages").push(new_msg);
    },
  },
};
</script>

<style>
</style>