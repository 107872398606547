<template>
  <div class="mt-3">
    <v-btn
      class="ml-2"
      v-if="item.postState !== 'Archives'"
      @click="relocateTo('Archives')"
    >
      לארכיון
    </v-btn>
    <v-btn
      class="mx-2"
      v-if="item.postState !== 'NewPosts'"
      @click="relocateTo('NewPosts')"
      >פרסם</v-btn
    >
    <v-btn
      class="mx-2"
      v-if="item.postState !== 'Tamples'"
      @click="relocateTo('Tamples')"
      >לטיוטות</v-btn
    >
    <v-btn
      dark
      elevation="5"
      class="mx-2"
      color="deep-orange lighten-2"
      v-if="item.postState === 'inProgress'"
      @click="relocateTo('done')"
      >בוצע</v-btn
    >
    <v-btn
      class="mx-2"
      color="error"
      @click="deleteItem"
      v-if="item.postState !== 'inProgress'"
      >מחק</v-btn
    >
    <RFormNewRecord
      v-if="item.postState !== 'inProgress' && item.postState !== 'done'"
      :editMode="true"
      :fields="headers"
      :values="JSON.parse(JSON.stringify(this.item))"
      :buttonName="'עריכה'"
    />
    <RModalShowProposals :post="item" />
    <!-- <v-dialog scrollable persistent v-model="showDialog" max-width="900">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="deep-purple darken-2"
          class="float-left white--text"
          v-bind="attrs"
          v-on="on"
          :disabled="proposalRecivedCounter === 0"
        >
          הצעות ({{ proposalRecivedCounter }})
        </v-btn>
      </template>
      <v-card class="elevation-9 rounded-xl">
        <v-card-title
          style="position: sticky"
          class="deep-purple darken-2 mb-5"
        >
          <span class="white--text mx-1"> הצעות </span>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="showDialog = false">mdi-close </v-icon>
        </v-card-title>
        <v-card-text flat tile>
          <v-card
            class="mx-1 my-5 elevation-9 rounded-xl"
            v-for="porposal in proposalRecivedForOnePost"
            :key="porposal.id"
          >
            <v-card-title
              class="deep-orange lighten-2 mb-5"
              v-if="porposal.proposalerName"
            >
              <span class="white--text">{{
                porposal.proposalerName.name
              }}</span>

              <span class="caption text--secondary mx-2 mt-1">
                {{ porposal.timeStamp | date("date") }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                class="deep-purple darken-2 rounded-lg mx-3 white--text"
                color="white"
                @click="agreeToProposal(porposal)"
                v-if="item.postState != 'inProgress'"
              >
                אשר עסקה
              </v-btn>
              <RFormNewMessage
                :buttonName="'שלח הודעה'"
                :disabled="porposal.proposalerName.uid === UID"
                :to="porposal.proposalerName.uid"
              />
            </v-card-title>
            <v-card-text flat tile v-if="porposal.proposalerName">
              <div class="text-subtitle-1">
                שם: {{ porposal.proposalerName.name }}
              </div>
              <div class="text-subtitle-1">
                דוא"ל: {{ porposal.proposalerName.email }}
              </div>
              <div class="text-subtitle-1">
                טלפון: {{ porposal.proposalerName.phoneNumber }}
              </div>
              <div class="text-subtitle-1" v-if="porposal.price">
                הערות:
                {{ porposal.comments }}
              </div>
              <div class="text-subtitle-1" v-if="porposal.price">
                מחיר שהציעו:
                {{ porposal.price }}
                <v-icon left>mdi-currency-ils</v-icon>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                dark
                class="mx-2 mb-4"
                color="deep-orange lighten-2"
                v-if="item.postState === 'inProgress'"
                @click="relocateTo('done')"
                >בוצע</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fire from "@/main";

import RFormNewRecord from "@/components/app/common/Form/RFormNewRecord/RFormNewRecord";
// import RFormNewMessage from "@/components/app/common/Form/RFormNewMessage";
import RModalShowProposals from "@/components/app/common/modals/RModalShowProposals";

export default {
  props: {
    // proposalRecivedForOnePost: {},
    // proposalRecivedCounter: {},
    headers: {},
    item: {},
  },
  data() {
    return {
      showDialog: false,
      showEditDialog: false,
    };
  },
  methods: {
    async relocateTo(target) {
      delete this.item.showfterFiltered;
      await fire.database().ref(`recors/lagguage/${target}`).push(this.item);
      await this.deleteItem();
    },
    // async agreeToProposal(payload) {
    //   delete payload.proposalerName;
    //   payload.agreed = true;
    //   await this.$store.dispatch("updatePorposal", payload);
    //   await this.relocateTo("inProgress");
    // },
    async deleteItem() {
      delete this.item.showfterFiltered;
      await fire
        .database()
        .ref(`recors/lagguage/${this.item.postState}/${this.item.currentID}`)
        .remove();
    },
  },

  components: {
    RFormNewRecord,
    // RFormNewMessage,
    RModalShowProposals,
  },
  computed: {
    ...mapGetters(["UID"]),
  },
  mounted() {
    // console.log({ proposalRecivedForOnePost: this.proposalRecivedForOnePost });
  },
};
</script>

<style>
</style>