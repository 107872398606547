<template>
  <div class="my-0 py-0">
    <v-subheader inset class="my-0 py-0 mb-n3">{{
      filterColum.text
    }}</v-subheader>
    <tr>
      <td>
        <v-btn
          icon
          text
          small
          color="red"
          @click="clearFilter()"
          class="mt-2"
          v-if="notEmptyFilter()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </td>
      <td>
        <v-text-field
          color="deep-orange lighten-2"
          dense
          outlined
          @change="containsUpdated()"
          type="number"
          :label="'מ'"
          v-model="filterColum.minMax[0]"
        ></v-text-field>
      </td>
      <td><v-icon small>mdi-minus</v-icon></td>
      <td>
        <v-text-field
          color="deep-orange lighten-2"
          dense
          outlined
          @change="containsUpdated()"
          type="number"
          :label="'עד'"
          v-model="filterColum.minMax[1]"
        >
        </v-text-field>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  props: ["filterColum", "records"],
  computed: {
    minMaxHigh() {
      if (this.filterColum.minMax != undefined) {
        if (
          this.filterColum.minMax[1] === undefined ||
          this.filterColum.minMax[1] === ""
        ) {
          return Infinity;
        }
        return this.filterColum.minMax[1];
      }
      return Infinity;
    },
    minMaxLow() {
      return Math.max(0, this.filterColum.minMax[0]);
    },
  },
  methods: {
    containsUpdated() {
      if (!this.notEmptyFilter()) {
        return;
      }
      this.records.forEach((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        if (
          parseInt(i[this.filterColum.value]) >= this.minMaxLow &&
          parseInt(i[this.filterColum.value]) <= this.minMaxHigh
        ) {
          i.showfterFiltered[this.filterColum.value] = true;
        } else {
          i.showfterFiltered[this.filterColum.value] = false;
        }
        return i;
      });
      this.$emit("containsUpdated");
    },
    clearFilter() {
      this.records.filter((i) => {
        if (i.showfterFiltered === undefined) {
          i.showfterFiltered = [];
        }
        i.showfterFiltered[this.filterColum.value] = true;
        return i;
      });
      this.filterColum.minMax = ["", ""];
      this.$emit("containsUpdated");
    },
    notEmptyFilter() {
      if (this.filterColum.minMax === undefined) {
        this.filterColum.minMax = ["", ""];
      }
      if (
        this.filterColum.minMax[0].length ||
        this.filterColum.minMax[1].length
      ) {
        return true;
      }
    },
  },
  created() {
    if (this.filterColum.minMax === undefined) {
      this.filterColum.minMax = ["", ""];
    }
  },
};
</script>

<style>
</style>