<template>
  <div>
    <OneTable
      :mainHeader="'פורסם'"
      :postState="'NewPosts'"
      :allUsers="allUsers"
      :trucking="trucking"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import OneTable from "@/components/app/common/Table/OneTable2";
export default {
  props: {
    allUsers: {
      default: false,
    },
    trucking: {
      default: false,
    },
  },
  components: {
    OneTable,
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style>
</style>