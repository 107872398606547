<template>
  <v-card flat class="mx-auto my-12" max-width="90vw">
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loading">
    </v-progress-linear>
    <NewPosts :allUsers="true" v-if="!loading" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import NewPosts from "@/components/app/MyLuggage/pages/NewPosts2.vue";
export default {
  name: "Luggage",
  data: () => ({
    // loading: false,
  }),
  methods: {},
  components: {
    NewPosts,
  },

  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("lagguageHeaders");
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>
