<template>
  <div>
    <!-- <div v-for="rec in filteredRecordsNow()" :key="rec.id">
      {{ rec.id }}-{{ rec.showfterFiltered }}
    </div> -->
    <!-- <v-btn @click="loadingTable = !loadingTable"></v-btn> -->
    <v-data-table
      :headers="headersFiltered"
      :items="filteredRecords"
      :single-expand="false"
      :expanded.sync="expanded"
      item-key="name"
      class=""
      @click:row="clickRow"
      multi-sort
      :key="updateTable"
      :itemKey="'id'"
      :loading="loadingTable"
    >
      <template v-slot:top>
        <FilterCard
          :HeadersFiltered="headersFiltered"
          :records="records"
          @containsUpdated="containsUpdated"
          @addDialog="addDialog = true"
        />
        <v-text-field
          class="mx-9"
          clearable
          :label="'חיפוס לפי מספר מודעה'"
          v-model="searchPostID"
          outlined
          prepend-inner-icon="mdi-clipboard-search-outline"
          color="deep-orange lighten-2"
        ></v-text-field>
        <v-toolbar flat>
          <v-toolbar-title>{{ mainHeader }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-3"
            color="red darken-1"
            :disabled="expanded.length === 0"
            text
            @click="expanded = []"
          >
            סגור הכל
          </v-btn>
          <v-dialog scrollable v-model="addDialog" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                color="deep-orange lighten-2"
                v-bind="attrs"
                v-on="on"
              >
                בחר טורים
                <v-icon right>mdi-format-columns</v-icon>
              </v-btn>
            </template>
            <SelectedColumns
              :title="'טורים'"
              :items="allHeaders"
              @Agree="addAgree"
              @Cancel="addCancel"
            />
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ExpandedCard
            :item="item"
            :headers="allHeaders"
            :allUsers="allUsers"
            :trucking="trucking"
            @relocateTo="relocateTo"
            @containsUpdated="containsUpdated"
          />
        </td>
      </template>

      <template v-slot:item.fromCity="{ item }">
        <v-icon :dark="false" color="primary" class="mx-1" left
          >mdi-alpha-a-circle</v-icon
        >{{ item.fromCity }}
      </template>
      <template v-slot:item.toCity="{ item }">
        <!-- <v-chip> -->
        <v-icon :dark="false" color="primary" class="mx-1" left
          >mdi-alpha-b-circle</v-icon
        >{{ item.toCity }}
        <!-- </v-chip> -->
      </template>
      <template v-slot:item.startDate="{ item }">
        <!-- <v-chip> -->
        <v-icon :dark="false" color="deep-orange lighten-2" class="mx-1" left
          >mdi-calendar</v-icon
        >{{ item.startDate }}
        <!-- </v-chip> -->
      </template>
      <template v-slot:item.crane="{ item }">
        <v-icon
          :dark="false"
          v-if="item.crane === true || item.crane === 'true'"
          >mdi-tow-truck</v-icon
        >
      </template>
      <template
        v-for="checkBoxeItem in checkBoxeItems"
        v-slot:[`item.${checkBoxeItem.name}`]="{ item }"
      >
        <v-icon
          :key="checkBoxeItem.name"
          color="green"
          :dark="false"
          v-if="
            item[checkBoxeItem.name] === true ||
            item[checkBoxeItem.name] === 'true'
          "
          >mdi-check-bold</v-icon
        >
        <v-icon
          :key="checkBoxeItem.name"
          color="red"
          :dark="false"
          v-if="
            item[checkBoxeItem.name] === false ||
            item[checkBoxeItem.name] === 'false'
          "
          >mdi-close-thick</v-icon
        >
      </template>
      <template v-slot:item.portersNeed="{ item }">
        <v-icon
          :dark="false"
          v-if="item.portersNeed === true || item.crane === 'true'"
          >mdi-account-hard-hat</v-icon
        >
      </template>
      <template v-slot:item.price="{ item }">
        <div v-if="item.price !== 0 && item.price != ''">
          {{ item.price }}
          <span
            :dark="false"
            color="primary"
            class="text--disabled text-caption"
            >ש"ח</span
          >
        </div>
        <div v-else>
          <v-icon :dark="false" color="deep-orange lighten-2" class="mx-1" left
            >mdi-minus</v-icon
          >
        </div>
      </template>
      <template v-slot:item.coments="{ item }">
        <div v-if="item.coments.length">
          {{ item.coments.substring(0, 12) }}
          <span v-if="item.coments.length > 12">...</span>
        </div>

        <!-- </v-chip> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fire from "@/main";

import { sortByMixin } from "@/mixins/tables/sortByVuexMixin.js";

// import { prepareHeadersForFilteringMixin } from "@/mixins/tables/prepareHeadersForFilteringMixin";

//form elements
import FilterCard from "@/components/app/common/Filters/FilterCard";

import SelectedColumns from "@/components/app/common/Table/elements/SelectedColumns";
import ExpandedCard from "@/components/app/common/Table/elements/ExpandedCard";
export default {
  // mixins: [prepareHeadersForFilteringMixin],
  props: {
    mainHeader: {
      default: "כותרת התבלה",
    },
    postState: {
      default: "",
    },
    allUsers: {
      default: false,
    },
    trucking: {
      default: false,
    },
  },
  data() {
    return {
      searchPostID: "",
      loadingTable: true,
      updateTable: false,
      expanded: [],
      addDialog: false,
      allHeaders: [],
      records: [],
      checkBoxeItems: [{ name: "raiseTail" }, { name: "includeMaam" }],
      sortBy: sortByMixin,
    };
  },
  computed: {
    ...mapGetters(["loading", "lagguageHeaders", "UID"]),
    filteredRecords() {
      let f_rec = this.filteredRecordsNow();
      this.containsUpdated();
      return f_rec;
    },
    headersFiltered() {
      let dessertHeadersFiltered_tmp = this.allHeaders.filter(
        (v) => v.selected === true
      );
      return dessertHeadersFiltered_tmp;
    },
  },
  methods: {
    filteredRecordsNow() {
      let f_rec = this.records.filter((r) => {
        if (r.showfterFiltered === undefined) {
          return r;
        }
        // console.log("r.showfterFiltered=");
        // console.log(r.showfterFiltered);
        let toShow = true;

        for (let [k, filter_tmp] of Object.entries(r.showfterFiltered)) {
          // r.showfterFiltered.forEach((filter_tmp) => {
          k;
          console.log("filter_tmp=" + filter_tmp);
          if (filter_tmp === false) {
            toShow = false;
          }
        }
        if (toShow) {
          return r;
        }
      });
      //only up-to-date (for allUsers anly)
      if (this.allUsers) {
        let nowDate = new Date(Date.now() - 86400000);
        f_rec = f_rec.filter((v) => {
          let startDate = new Date(v.startDate);
          if (+startDate >= +nowDate) {
            return v;
          }
        });
      }
      //filter search by idPost form
      if (
        this.searchPostID != "" &&
        this.searchPostID != undefined &&
        this.searchPostID != null
      ) {
        f_rec = f_rec.filter((r) => {
          if (r.id.includes(this.searchPostID)) {
            return r;
          }
        });
      }
      console.log({ f_rec: f_rec });
      return f_rec;
    },
    async relocateTo(payload) {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("relocateLagguage", {
        val: payload.val,
        sourse: this.postState,
        target: payload.target,
      });
      await this.$store.dispatch("loading", false);
    },
    containsUpdated() {
      this.updateTable = !this.updateTable;
    },
    clickRow(obj, item) {
      item.expand(!item.isExpanded);
    },
    addAgree(newHeadersSelected) {
      this.allHeaders = this.allHeaders.map((element) => {
        if (newHeadersSelected.indexOf(element.text) > -1) {
          element.selected = true;
        } else {
          element.selected = false;
        }
        return element;
      });

      this.addDialog = false;
    },
    addCancel() {
      this.addDialog = false;
    },
    async loadRecords() {
      let viewMessage = this;
      let itemsRef = null;
      // itemsRef = await fire
      //   .database()
      //   .ref(`recors/lagguage/${this.postState}`)
      //   .orderByChild("uid").equalTo(this.UID);

      if (this.allUsers === false) {
        itemsRef = await fire
          .database()
          .ref(`recors/lagguage/${this.postState}`)
          .orderByChild("uid")
          .equalTo(this.UID);
      } else {
        itemsRef = await fire
          .database()
          .ref(`recors/lagguage/${this.postState}`)
      }

      await itemsRef.on("value", (snapshot) => {
        let data = snapshot.val();
        if (data) {
          this.loadingTable = true;
          viewMessage.records.length = 0;
          let arr_tmp = [];
          let keys_arr = Object.keys(data);
          for (let key of keys_arr) {
            arr_tmp.push({
              id: key,
              ...data[key],
              currentID: key,
              postState: this.postState,
            });
          }
          viewMessage.records = arr_tmp;
        } else {
          viewMessage.records = [];
        }
        //sortByDate
        viewMessage.records = this.sortBy(viewMessage.records, {
          prop: "startDate",
          desc: true,
          parser: function (item) {
            return new Date(item);
          },
        });
        this.allHeaders = JSON.parse(JSON.stringify(this.lagguageHeaders));
        viewMessage.loadingTable = false;
      });
    },
  },
  components: {
    SelectedColumns,
    ExpandedCard,
    FilterCard,
  },
  watch: {
    updateTable() {
      console.log("changed over updateTable()");
    },

    records() {
      console.log("records changed");
    },
  },
  async created() {
    await this.loadRecords();
  },
};
</script>