<template>
  <v-dialog persistent v-model="showDialog" max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="primary rounded-lg mx-3 white--text"
        color="white"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ buttonName }}
        <v-icon v-if="!disabled" right>mdi-message-text</v-icon>
      </v-btn>
    </template>
    <div>
      <v-card flat>
        <v-card-title class="primary white--text mb-5">
          הודעה חדשה
          <v-spacer></v-spacer>
          <v-icon color="white" @click="showDialog = false"> mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="d-flex flex-wrap" flat tile>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="הודע חדשה"
                  v-model="comments"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" class="white--text" color="primary">
            שלח הודעה
            <v-icon v-if="!disabled" right>mdi-message-text</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import fire from "@/main";
import { mapGetters } from "vuex";

export default {
  props: {
    buttonName: {
      default: "edit",
    },
    disabled: {
      default: false,
    },
    startPrice: {
      default: 0,
    },
    to: {
      default: "111",
    },
  },
  data() {
    return {
      showDialog: false,
      comments: "",
    };
  },
  computed: {
    ...mapGetters(["UID"]),
  },
  created() {},
  methods: {
    save() {
      if (this.comments && this.comments != "") {
        let new_msg = {
          from: this.UID,
          to: this.to,
          msg_content: this.comments,
          unread: true,
          timestamp: Date(),
        };
        fire.database().ref("messages").push(new_msg);
        // this.resetIcon();
        // this.clearMessage();
        console.log(new_msg);
      }

      this.showDialog = false;
    },
  },
};
</script>

<style>
</style>